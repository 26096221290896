// import { useTranslation } from 'react-i18next'
import {
  ConsolidatedAccountDtoTransferOptionEnum,
  ClientInformationDtoResidentialAddressAreaEnum,
  ClientInformationDtoOccupationEnum,
  ClientInformationDtoMaritalStateEnum,
  ClientInformationDtoEducationLevelAlphaEnum,
  ClientInformationDtoFamilyStatusEnum,
  PirsRegulatedActivitiesDtoEducationLevelEnum,
} from 'client/api/application/api'

export const appInsightKey = process.env.REACT_APP_APPINSIGHT || ''
export const appInsightConnStr = process.env.REACT_APP_APPINSIGHT_CONNSTR || ''

export const defaultLang = 'zh-Hant'
export const isDev =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
export const isProd = process.env.REACT_APP_ENV === 'prod'
export const requireLogin = !!process.env.REACT_APP_LOGIN || false
export const buildId = process.env.REACT_APP_BUILD_ID
export const BASE_PATH = process.env.REACT_APP_WEBAPI_ENDPOINT
export const PPO_BASE_PATH = process.env.REACT_APP_WEBAPI_PPO_ENDPOINT
export const devSecret = process.env.REACT_APP_DEV_SECRET || ''
// redux action
export const ADD_FORM1 = 'ADD_FORM1'

export const SESSION_STORAGE_LOCALE = 'locale'
export const baseName = process.env.REACT_APP_BASENAME || 'pa-axa'

export const adminRole = [
  'AXA-PPO',
  'FTLIFE-OFFICE',
  'CONVOY-OFFICE',
  'COREPAC-OFFICE',
  'NOBLEAPEX-OFFICE',
  'CIS-OFFICE',
  'AMPLE-OFFICE',
  'COMPASS-OFFICE',
  'GCWRM-OFFICE',
  'FRONTIER-OFFICE',
  'MIDLAND-OFFICE',
  'IPG-OFFICE',
  'AMG-OFFICE',
  'CENTALINE-OFFICE',
  'KGI-OFFICE',
  'MR-OFFICE',
  'IFAST-OFFICE',
  'KINGKEY-OFFICE',
  'IPPFA-OFFICE',
  'TF-OFFICE',
  'BANK-OFFICE',
  'KARL-OFFICE',
  'CASH-OFFICE',
  'CENTURYINV-OFFICE',
  'TAIPING-OFFICE',
  'ONEPLATFORM-OFFICE',
  'RM-OFFICE',
]

export const natureOfBusiness = {
  1: 'Catering',
  2: 'Construction',
  3: 'Manufacturing',
  4: 'Financing / Banking Services',
  5: 'Community / Social / Personal Services',
  6: 'Wholesale /Retail / Import & Export',
  7: 'Transport',
  8: 'Cleaning',
  9: 'Security Guard',
  10: 'Hairdressing & Beauty',
}

export const acccountTransferOpt = [
  {
    label: 'Transfer All',
    value: ConsolidatedAccountDtoTransferOptionEnum.A,
  },
  {
    label: 'Transfer Partial',
    value: ConsolidatedAccountDtoTransferOptionEnum.P,
  },
]

/**
 * Names of channel which has the remote sign feature enabled
 */
export const remoteSignEnabled = [
  'AXA',
  'TAIPING',
  'FTLIFE',
  'EVERON',
  'TRANSPAC',
  'ABLTD',
  'MARCO',
  'OHWM',
  'SESG',
]

// export const natureOfBusiness = [
//   { value: '1', label: 'Catering' },
//   { value: '2', label: 'Construction' },
//   { value: '3', label: 'Manufacturing' },
//   { value: '4', label: 'Financing / Banking Services' },
//   { value: '5', label: 'Community / Social / Personal Services' },
//   { value: '6', label: 'Wholesale /Retail / Import & Export' },
//   { value: '7', label: 'Transport' },
//   { value: '8', label: 'Cleaning' },
//   { value: '9', label: 'Security Guard' },
//   { value: '10', label: 'Hairdressing & Beauty' },
// ]

// interface natureOfBusinessItem {
//   value: string
//   label: string
// }
// export const natureOfBusiness:natureOfBusinessItem[] = []

export const principalInvestment = [
  {
    name: 'Default Investment Strategy',
    code: 'DIS',
    risk: '',
  },
  {
    name: 'Principal Age 65 Plus Fund',
    code: 'A65F',
    risk: 'M',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/A65F_800_202206.pdf',
  },
  {
    name: 'Principal Core Accumulation Fund',
    code: 'CAF',
    risk: 'MH',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/CAF_800_202206.pdf',
  },
  {
    name: 'Principal MPF Conservative Fund',
    code: 'MCF',
    risk: 'L',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/CPF_800_202206.pdf',
  },
  {
    name: 'Principal HK Dollar Savings Fund',
    code: 'HKDSF',
    risk: 'L',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/HKDSF_800_202206.pdf',
  },
  {
    name: 'Principal Asian Bond Fund',
    code: 'ABF',
    risk: 'M',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/ABF_800_202206.pdf',
  },
  {
    name: 'Principal Hong Kong Bond Fund',
    code: 'HKBF',
    risk: 'M',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/HKBF_800_202206.pdf',
  },
  {
    name: 'Principal International Bond Fund',
    code: 'IBF',
    risk: 'M',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/IBF_800_202206.pdf',
  },
  {
    name: 'Principal Stable Yield Fund',
    code: 'SYF',
    risk: 'M',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/SYF_800_202206.pdf',
  },
  {
    name: 'Principal Long Term Accumulation Fund',
    code: 'LTAF',
    risk: 'MH',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/LTAF_800_202206.pdf',
  },
  {
    name: 'Principal Global Growth Fund',
    code: 'GGF',
    risk: 'MH',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/GGF_800_202206.pdf',
  },
  {
    name: 'Principal Aggressive Strategy Fund',
    code: 'ASF',
    risk: 'H',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/ASF_800_202206.pdf',
  },
  {
    name: 'Principal US Equity Fund',
    code: 'USEF',
    risk: 'H',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/USEF_800_202206.pdf',
  },
  {
    name: 'Principal Asian Equity Fund',
    code: 'AEF',
    risk: 'H',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/AEF_800_202206.pdf',
  },
  {
    name: 'Principal China Equity Fund',
    code: 'CEF',
    risk: 'H',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/CEF_800_202206.pdf',
  },
  {
    name: 'Principal Hang Seng Index Tracking Fund',
    code: 'HSITF',
    risk: 'H',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/HSITF_800_202206.pdf',
  },
  {
    name: 'Principal Hong Kong Equity Fund',
    code: 'HKEF',
    risk: 'H',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/HKEF_800_202206.pdf',
  },
  {
    name: 'Principal International Equity Fund',
    code: 'IEF',
    risk: 'H',
    infoUrl:
      'https://rsc.principal.com.hk/Principal.WebApp/download/FundFactSheet/IEF_800_202206.pdf',
  },
]

export const hkArea = [
  {
    value: ClientInformationDtoResidentialAddressAreaEnum.HK,
    label: 'Hong Kong',
  },
  {
    value: ClientInformationDtoResidentialAddressAreaEnum.KLN,
    label: 'Kowloon',
  },
  {
    value: ClientInformationDtoResidentialAddressAreaEnum.NT,
    label: 'New Territories',
  },
  {
    value: ClientInformationDtoResidentialAddressAreaEnum.Others,
    label: 'Others',
  },
]

export const UPLOAD_SIZE_LIMIT: number = 10 * 1024 * 1024

export interface trusteeDescription {
  [trusteeCode: string]: string
}

export interface schemeDescription {
  [schemeCode: string]: string
}

export const TRUSTEE_GROUP_HSBC_PROVIDENT_FUND =
  '19697,86591,1572900,28325,19689'
const TRUSTEE_DESC_MAP_HSBC_PROVIDENT_FUND =
  'HSBC Provident Fund Trustee (Hong Kong) Limited'
const TRUSTEE_GROUP_MAP_HSBC_PROVIDENT_FUND =
  TRUSTEE_GROUP_HSBC_PROVIDENT_FUND.split(',').reduce(
    (acc: any, trusteeCdoe: string) => {
      return {
        ...acc,
        [trusteeCdoe]: TRUSTEE_DESC_MAP_HSBC_PROVIDENT_FUND,
      }
    },
    {
      [TRUSTEE_GROUP_HSBC_PROVIDENT_FUND]: TRUSTEE_DESC_MAP_HSBC_PROVIDENT_FUND,
    },
  )
export const trusteeMap: trusteeDescription = {
  '19684': 'AIA Company (Trustee) Limited',
  '19687': 'Bank of Communications Trustee Limited',
  '19686': 'Bank Consortium Trust Company Limited',
  '19688': 'Bank of East Asia (Trustee) Limited',
  '19690': 'BOCI-Prudential Trustee Limited',
  '19693': 'China Life Trustees Limited',
  // '19689': 'HSBC Institutional Trust Services (Asia) Ltd',
  ...TRUSTEE_GROUP_MAP_HSBC_PROVIDENT_FUND,
  '19698': 'Manulife Provident Funds Trust Company',
  '44184': 'YF Life Trustees Limited',
  // '19683': 'Sun Life Pension Trust Limited',
  '19700': 'Principal Trust Company (Asia) Limited',
  '19695': 'Sun Life Trustee Company Limited',
  '19691': 'Standard Chartered Trustee (Hong Kong) Limited',
}

export const schemeMap: schemeDescription = {
  '19686-2': 'BCT(MPF) Industry Choice',
  '19686-1': 'BCT(MPF) Pro Choice',
  '19686-6': 'Manulife RetireChoice (MPF) Scheme',
  '19686-4': 'BCT Strategic MPF Scheme',
  '19686-5': 'AMTD MPF Scheme',
  '19687-2': 'BCOM Joyful Retirement MPF Scheme',
  '19684-2': 'AIA MPF Prime Value Choice',
  '19688-2': 'BEA (MPF) Industry Scheme',
  '19688-1': 'BEA (MPF) Master Trust Scheme',
  '19688-3': 'BEA (MPF) Value Scheme',
  '19690-1': 'BOC-Prudential Easy-Choice Mandatory Provident Fund Scheme',
  '19690-3': 'My Choice Mandatory Provident Fund Scheme',
  '19693-1': 'China Life MPF Master Trust Scheme',
  // '19689-6': 'Fidelity Retirement Master Trust',
  '28325-2': 'Haitong MPF Retirement Fund',
  '19697-2': 'Hang Seng Mandatory Provident Fund Supertrust Plus',
  '19697-4': 'HSBC Mandatory Provident Fund-Supertrust Plus',
  //'1572900-1': 'Fidelity Retirement Master Trust',
  '28325-1': 'Fidelity Retirement Master Trust',
  '19698-2': 'Manulife Global Select (MPF) Scheme',
  '44184-1': 'Mass Mandatory Provident Fund Scheme',
  // '19683-1': 'Sun Life MPF Basic Scheme',
  // '19683-2': 'Sun Life MPF Comprehensive Scheme',
  // '19700-3': 'Principal MPF Scheme Series 600',
  '19700-4': 'Principal MPF Scheme Series 800',
  '19700-12': 'Principal MPF-Simple Plan',
  '19700-11': 'Principal MPF-Smart Plan',
  '19695-2': 'Sun Life Rainbow MPF Scheme',
  '19691-3': 'SHKP MPF Employer Sponsored Scheme',
}

export function mapToTrusteeGroup(trusteeCode: string) {
  if (
    TRUSTEE_GROUP_HSBC_PROVIDENT_FUND.split(',').findIndex((code: string) => {
      return code === trusteeCode
    }) > -1
  ) {
    return TRUSTEE_GROUP_HSBC_PROVIDENT_FUND
  }

  return trusteeCode
}

/**
 * 19697,86591,1572900, 28325 are grouoped to TRUSTEE_GROUP_HSBC_PROVIDENT_FUND(19697,86591,1572900,28325)
 */
export const trusteeName = [
  { label: trusteeMap['19684'], value: '19684' },
  { label: trusteeMap['19686'], value: '19686' },
  { label: trusteeMap['19687'], value: '19687' },
  { label: trusteeMap['19688'], value: '19688' },
  { label: trusteeMap['19690'], value: '19690' },
  { label: trusteeMap['19693'], value: '19693' },
  // { label: trusteeMap['19689'], value: '19689' },
  {
    label: trusteeMap[TRUSTEE_GROUP_HSBC_PROVIDENT_FUND],
    value: TRUSTEE_GROUP_HSBC_PROVIDENT_FUND,
  },
  { label: trusteeMap['19698'], value: '19698' },
  { label: trusteeMap['19700'], value: '19700' },
  // { label: trusteeMap['19683'], value: '19683' },
  { label: trusteeMap['19695'], value: '19695' },
  { label: trusteeMap['44184'], value: '44184' },
  { label: trusteeMap['19691'], value: '19691' },
]

export const schemesBCT = [
  { label: schemeMap['19686-6'], value: '19686,19686-6' },
  { label: schemeMap['19686-5'], value: '19686,19686-5' },
  { label: schemeMap['19686-2'], value: '19686,19686-2' },
  { label: schemeMap['19686-1'], value: '19686,19686-1' },
  { label: schemeMap['19686-4'], value: '19686,19686-4' },
]

export const schemesBOC = [
  { label: schemeMap['19687-2'], value: '19687,19687-2' },
]

export const schemesAIA = [
  { label: schemeMap['19684-2'], value: '19684,19684-2' },
]

export const schemesBEA = [
  { label: schemeMap['19688-1'], value: '19688,19688-1' },
  { label: schemeMap['19688-2'], value: '19688,19688-2' },
  { label: schemeMap['19688-3'], value: '19688,19688-3' },
]

export const schemesBOCI = [
  {
    label: schemeMap['19690-1'],
    value: '19690,19690-1',
  },
  { label: schemeMap['19690-3'], value: '19690,19690-3' },
]

export const schemesCL = [
  { label: schemeMap['19693-1'], value: '19693,19693-1' },
]

export const schemesHSBC = [
  { label: schemeMap['19689-6'], value: '19689,19689-6' },
  { label: schemeMap['28325-2'], value: '19689,28325-2' },
]

export const schemesHSBCProv = [
  {
    label: schemeMap['19697-2'],
    value: '19697,19697-2',
  },
  { label: schemeMap['19697-4'], value: '19697,19697-4' },
  //{ label: schemeMap['1572900-1'], value: '1572900,1572900-1' },
  { label: schemeMap['28325-1'], value: '28325,28325-1' },
  { label: schemeMap['28325-2'], value: '28325,28325-2' },
]

export const schemesManulife = [
  { label: schemeMap['19698-2'], value: '19698,19698-2' },
]

export const schemesYFLife = [
  { label: schemeMap['44184-1'], value: '44184,44184-1' },
]

export const schemesSunLife = [
  { label: schemeMap['19683-1'], value: '19683,19683-1' },
  { label: schemeMap['19683-2'], value: '19683,19683-2' },
]

export const schemesPrincipal = [
  { label: schemeMap['19700-3'], value: '19700,19700-3' },
  { label: schemeMap['19700-4'], value: '19700,19700-4' },
  { label: schemeMap['19700-12'], value: '19700,19700-12' },
  { label: schemeMap['19700-11'], value: '19700,19700-11' },
]

export const schemesSunLifeTrustee = [
  { label: schemeMap['19695-2'], value: '19695,19695-2' },
]

export const schemesSHKP = [
  { label: schemeMap['19691-3'], value: '19691,19691-3' },
]

export const nationalities = [
  // 'Hong Kong SAR',
  'Chinese',
  'American',
  'Australian',
  'British',
  'Canadian',
  //'Filipino',
  'French',
  'Indian',
  'Indonesian',
  'Italian',
  'Japanese',
  'Korean',
  'Malaysian',
  'Nepalese',
  'New Zealand',
  'Pakistani',
  'Philippine',
  'Singaporean',
  'Taiwanese',
  'Thai',
]

// const configuration = {
//   client_id: 'spa.short',
//   redirect_uri: 'http://localhost:3000/authentication/callback',
//   response_type: 'code',
//   post_logout_redirect_uri: 'http://localhost:3000/',
//   scope: 'openid profile email api offline_access',
//   authority: 'https://demo.identityserver.io',
//   silent_redirect_uri: 'http://localhost:3000/authentication/silent_callback',
//   automaticSilentRenew: true,
//   loadUserInfo: true,
//   triggerAuthFlow: true
// };

const oidcClientIds = process.env.REACT_APP_CLIENT_ID || 'axa,core'
export const oidcClientIdsArr = oidcClientIds.split(',')

// read default from env, current default is axa
export const oidcConfiguration = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: oidcClientIdsArr[0],
  response_type: 'code',
  scope: process.env.REACT_APP_SCOPE,
  // "redirect_uri": "https://poc-webapp-01.azurewebsites.net/callback.html",
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: false,
  monitorSession: process.env.REACT_APP_OIDC_MONSESS,
  loadUserInfo: true,
  triggerAuthFlow: true,
  checkSessionInterval: 10000,
  ui_locales: 'zh-hant',
}

export const oidcCoreLogoutURI =
  process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI_CORE ||
  'https://apps.principal.com.hk/pa-core'
export const oidcCoreRedirectURI =
  process.env.REACT_APP_OIDC_REDIRECT_URI_CORE ||
  'https://apps.principal.com.hk/pa-core/callback'
export const oidcCoreSilentURI =
  process.env.REACT_APP_OIDC_SILENT_REDIRECT_URI_CORE ||
  'https://apps.principal.com.hk/pa-core/silent_callback'

export const oidcDevConfiguration = {
  authority: 'https://pihk-ipalogin-sit.azurewebsites.net/pa-login',
  client_id: 'ipa-sit',
  response_type: 'code',
  scope: 'openid profile pa-axa.agent offline_access',
  // "redirect_uri": "https://poc-webapp-01.azurewebsites.net/callback.html",
  redirect_uri: 'http://localhost:3000/pa-axa/callback',
  silent_redirect_uri: 'http://localhost:3000/pa-axa/callback',
  post_logout_redirect_uri: 'http://localhost:3000/pa-axa',
  automaticSilentRenew: false,
  monitorSession: false,
  loadUserInfo: true,
  triggerAuthFlow: true,
  checkSessionInterval: 10000,
  ui_locales: 'zh-hant',
}

export const maritalStatus = [
  {
    label: 'Single',
    value: ClientInformationDtoMaritalStateEnum.SINGLE,
  },
  {
    label: 'Married',
    value: ClientInformationDtoMaritalStateEnum.MARRIED,
  },
  {
    label: 'Widowed',
    value: ClientInformationDtoMaritalStateEnum.WIDOWED,
  },
  {
    label: 'Divorced',
    value: ClientInformationDtoMaritalStateEnum.DIVORCED,
  },
]

export const familyStatus = [
  {
    label: 'Single',
    value: ClientInformationDtoFamilyStatusEnum.SINGLE,
  },
  {
    label: 'Married',
    value: ClientInformationDtoFamilyStatusEnum.MARRIED,
  },
]

export const educationLvl = [
  {
    label: 'Primary',
    value: ClientInformationDtoEducationLevelAlphaEnum.PRIMARY,
  },
  {
    label: 'Secondary',
    value: ClientInformationDtoEducationLevelAlphaEnum.SECONDARY,
  },
  {
    label: 'Non-degree Tertiary',
    value: ClientInformationDtoEducationLevelAlphaEnum.NONDEGREETERITARY,
  },
  {
    label: 'Degree or above',
    value: ClientInformationDtoEducationLevelAlphaEnum.DEGREEORABOVE,
  },
]

export const pirsEducationLvl = [
  {
    label: 'Primary',
    value: ClientInformationDtoEducationLevelAlphaEnum.PRIMARY,
  },
  {
    label: 'Secondary',
    value: ClientInformationDtoEducationLevelAlphaEnum.SECONDARY,
  },
  {
    label: 'Degree or above',
    value: ClientInformationDtoEducationLevelAlphaEnum.DEGREEORABOVE,
  },
]

export const occupation = [
  {
    label: 'Finance / Insurance',
    value: ClientInformationDtoOccupationEnum.FINANCE,
  },
  {
    label: 'Engineers',
    value: ClientInformationDtoOccupationEnum.ENGINEERS,
  },
  {
    label: 'Professionals',
    value: ClientInformationDtoOccupationEnum.PROFESSIONALS,
  },
  {
    label: 'Customer Services',
    value: ClientInformationDtoOccupationEnum.CUSTOMERSERVICES,
  },
  {
    label: 'Information Technology',
    value: ClientInformationDtoOccupationEnum.INFORMATIONTECH,
  },
  {
    label: 'Housewife',
    value: ClientInformationDtoOccupationEnum.HOUSEWIFE,
  },
  {
    label: 'Retired',
    value: ClientInformationDtoOccupationEnum.RETIRED,
  },
  {
    label: 'Unemployment',
    value: ClientInformationDtoOccupationEnum.UNEMPLOYMENT,
  },
  {
    label: 'Others',
    value: ClientInformationDtoOccupationEnum.OTHERS,
  },
]

export const occupationsV2 = [
  {
    label: 'Agriculture and Fisheries / Manufacturing',
    value: 'Agriculture and Fisheries / Manufacturing',
    child: [
      {
        label: 'Blacksmith, Toolmaker, Plumber, Welder, Stonecutter & Carver',
        value: 'Blacksmith, Toolmaker, Plumber, Welder, Stonecutter & Carver',
      },
      {
        label: 'Buyer, merchandiser',
        value: 'Buyer, merchandiser',
      },
      {
        label:
          'Carpenter, Wood Worker, Wood Preparation Worker, Logger, Forestry Worker',
        value:
          'Carpenter, Wood Worker, Wood Preparation Worker, Logger, Forestry Worker',
      },
      {
        label:
          'Crop & Farm Worker, Livestock Farmer, Other Agricultural Worker, Fisherman',
        value:
          'Crop & Farm Worker, Livestock Farmer, Other Agricultural Worker, Fisherman',
      },
      {
        label: 'Factory/Production supervisor/worker, Manual/Industrial Worker',
        value: 'Factory/Production supervisor/worker, Manual/Industrial Worker',
      },
      {
        label: 'Jewellery & Precious Metal Worker,  Metal Processor',
        value: 'Jewellery & Precious Metal Worker,  Metal Processor',
      },
      {
        label: 'Machinery Fitter, Assembler',
        value: 'Machinery Fitter, Assembler',
      },
      {
        label: 'Printer & Related, Paper pulp & Paper Maker',
        value: 'Printer & Related, Paper pulp & Paper Maker',
      },
      {
        label:
          'Spinner, Weaver, Knitter, Dyer, Tanner, Fellmonger, Felt Dresser',
        value:
          'Spinner, Weaver, Knitter, Dyer, Tanner, Fellmonger, Felt Dresser',
      },
    ],
  },
  {
    label: 'Auctioneer',
    value: 'Auctioneer',
  },
  {
    label: 'Banking / Financial Services / Business Professionals',
    value: 'Banking / Financial Services / Business Professionals',
    child: [
      {
        label: 'Accountant, auditor',
        value: 'Accountant, auditor',
      },
      {
        label: 'Banker',
        value: 'Banker',
      },
      {
        label: 'Business Executive, businessman, merchant',
        value: 'Business Executive, businessman, merchant',
      },
      {
        label: 'Chief Executive Officer',
        value: 'Chief Executive Officer',
      },
      {
        label:
          'Chief Officers: operations, legal, finance, technology, risk, internal audit, compliance, etc.',
        value:
          'Chief Officers: operations, legal, finance, technology, risk, internal audit, compliance, etc.',
      },
      {
        label: 'Company Director / Chairman',
        value: 'Company Director / Chairman',
      },
      {
        label: 'Economist',
        value: 'Economist',
      },
      {
        label: 'Financial analyst, business analyst',
        value: 'Financial analyst, business analyst',
      },
      {
        label: 'Human resources personnel, recruiters',
        value: 'Human resources personnel, recruiters',
      },
      {
        label:
          'Insurance agent/consultant, financial planner, real estate agent',
        value:
          'Insurance agent/consultant, financial planner, real estate agent',
      },
      {
        label: 'Market Research Field Worker',
        value: 'Market Research Field Worker',
      },
      {
        label: 'Statistician, Actuaries',
        value: 'Statistician, Actuaries',
      },
    ],
  },
  {
    label: 'Construction/Engineer',
    value: 'Construction/Engineer',
  },
  {
    label: 'Diplomatic Personnel',
    value: 'Diplomatic Personnel',
  },
  {
    label: 'Disciplined Services',
    value: 'Disciplined Services',
  },
  {
    label: 'Domestic / Property Service',
    value: 'Domestic / Property Service',
  },
  {
    label: 'Government Officials',
    value: 'Government Officials',
  },
  {
    label: 'Housewife',
    value: 'Housewife',
  },
  {
    label: 'Information Technology Professionals',
    value: 'Information Technology Professionals',
  },
  {
    label: 'Legal Professionals',
    value: 'Legal Professionals',
    child: [
      {
        label: 'Judge, Magistrate, Judicial Officer',
        value: 'Judge, Magistrate, Judicial Officer',
      },
      {
        label: 'Solicitor, lawyer, legal service personnel, company secretary',
        value: 'Solicitor, lawyer, legal service personnel, company secretary',
      },
    ],
  },
  {
    label: 'Life Science and Health Professionals',
    value: 'Life Science and Health Professionals',
  },
  {
    label: 'Middle Management / Administrative / Clerical',
    value: 'Middle Management / Administrative / Clerical',
  },
  {
    label: 'Money Changer',
    value: 'Money Changer',
  },
  {
    label: 'Other Professionals',
    value: 'Other Professionals',
    child: [
      {
        label: 'Athlete, Sportsman',
        value: 'Athlete, Sportsman',
      },
      {
        label: 'Author, Critic, Journalist',
        value: 'Author, Critic, Journalist',
      },
      {
        label: 'Biologist, Zoologist & related',
        value: 'Biologist, Zoologist & related',
      },
      {
        label: 'Commercial Artist & Designer',
        value: 'Commercial Artist & Designer',
      },
      {
        label: 'Film producer/technician and personnel',
        value: 'Film producer/technician and personnel',
      },
      {
        label: 'Geologist, Miner & Quarryman',
        value: 'Geologist, Miner & Quarryman',
      },
      {
        label: 'Glass/Potter & related maker',
        value: 'Glass/Potter & related maker',
      },
      {
        label: 'Medicine sales representative',
        value: 'Medicine sales representative',
      },
      {
        label: 'Other Production Worker',
        value: 'Other Production Worker',
      },
      {
        label: 'Performers',
        value: 'Performers',
      },
      {
        label: 'Petroleum Refining Worker',
        value: 'Petroleum Refining Worker',
      },
      {
        label: 'Promoter, Demonstrator',
        value: 'Promoter, Demonstrator',
      },
      {
        label: 'Rubber & Oil palm Processor, Rubber & Plastic Product Maker',
        value: 'Rubber & Oil palm Processor, Rubber & Plastic Product Maker',
      },
      {
        label: 'Sculptor, Painter, Artist',
        value: 'Sculptor, Painter, Artist',
      },
      {
        label: 'Shoe Maker & Leather Goods Maker',
        value: 'Shoe Maker & Leather Goods Maker',
      },
      {
        label: 'Still & Movie Cameraman',
        value: 'Still & Movie Cameraman',
      },
      {
        label: 'Street/News Vendor, Hawker',
        value: 'Street/News Vendor, Hawker',
      },
      {
        label: 'Tobacco Preparer & Cigar Maker',
        value: 'Tobacco Preparer & Cigar Maker',
      },
      {
        label: 'Upholsterer',
        value: 'Upholsterer',
      },
    ],
  },
  {
    label: 'Personal Care Services',
    value: 'Personal Care Services',
  },
  {
    label: 'Retail / Catering / Cleaning / Food Service Worker',
    value: 'Retail / Catering / Cleaning / Food Service Worker',
  },
  {
    label: 'Retiree',
    value: 'Retiree',
  },
  {
    label: 'Social Worker and Religious Services',
    value: 'Social Worker and Religious Services',
  },
  {
    label: 'Student',
    value: 'Student',
  },
  {
    label: 'Teacher / Education Professional',
    value: 'Teacher / Education Professional',
  },
  {
    label: 'Transportation / Logistics / Hospitality / Tourism',
    value: 'Transportation / Logistics / Hospitality / Tourism',
  },
  {
    label: 'Unemployed',
    value: 'Unemployed',
  },
]
